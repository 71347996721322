<script setup lang="ts">
import { add, sub } from 'date-fns';
import LineChart from '../chart/LineChart.vue';

const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const workspaceStore = useWorkspaceStore();
const { workspaceId } = storeToRefs(workspaceStore);

const dateRange = ref({
  start: sub(new Date(), { months: 1 }),
  end: add(new Date(), { days: 1 }),
});

const { data: insights, pending } = await useLazyApi(
  `/api/workspaces/${workspaceId.value}/insights`,
  {
    method: 'GET',
    query: dateRange,
  },
);

const chartData = computed(() => {
  if (!insights.value?.records) return { labels: [], data: [] };
  return {
    labels: insights.value.records.map((item) => item.date),
    data: insights.value.records.map((item) => item['step_run_cost']),
  };
});

const totalCost = computed(() => {
  if (!insights.value) return 0;
  return parseFloat(insights.value.totalStepRunCost.toFixed(2));
});
</script>
<template>
  <div
    class="border border-gray-200 shrink bg-white rounded-xl h-full w-full flex flex-col p-4"
  >
    <div class="flex justify-between mb-4 w-full items-start">
      <div v-if="insights" class="flex flex-col items-start">
        <span v-if="true" class="caption flex gap-1 leading-4">
          <p class="text-gray-500">Total Cost</p>
        </span>
        <span class="flex items-baseline gap-2">
          <p class="title">${{ totalCost }}</p>
        </span>
      </div>
      <USkeleton v-else-if="pending" class="h-20 w-40" />
      <AppCalendar v-model="dateRange" type="daterange" class="shrink-0" />
    </div>

    <LineChart
      v-if="insights"
      :labels="chartData.labels"
      :data="chartData.data"
      label="AI Usage"
      border-color="#9296fa"
      point-background-color="#f2f3fe"
      title="AI Usage"
      class="h-full w-full"
    />
    <USkeleton v-else-if="pending" class="h-40 w-full" />
  </div>
</template>
