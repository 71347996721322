<script setup lang="ts">
import { SubscriptionType } from '@respell/database';
import {
  stripeMonthlyTaskPriceId,
  stripeStarterMonthlyPriceId,
  stripeStarterYearlyPriceId,
  stripeYearlyTaskPriceId,
  subscriptionDetails,
  subscriptionTypeToMonthlyActionLimit,
  taskCost,
  type SubscriptionDetail,
} from '@respell/utils/subscription';
import ConfirmSubscriptionModal from '~/components/modals/ConfirmSubscriptionModal.vue';
import { formatCurrency } from '~/util/formats';

const toast = useToast();
const modal = useModal();

const workspaceStore = useWorkspaceStore();
const {
  workspace,
  workspaceId,
  monthlyAddonTasks,
  subscription,
  subscriptionType,
} = storeToRefs(workspaceStore);

const props = defineProps<{
  plan: SubscriptionDetail;
  term: string;
}>();
const { plan } = props;
const {
  name,
  type,
  min,
  isEnterprise,
  subscriptionPriceIds,
  subscriptionPricePerMonth,
  description,
  marketingTitle,
  marketingItems,
  highlighted,
} = plan;

const navigateToStripeSessionUrl = async () => {
  const sessionUrl = await $api(`/api/stripe/${workspaceId.value}/session`, {
    method: 'POST',
    body: {
      lineItems: lineItems.value,
      type: type,
    },
  });

  navigateTo(sessionUrl, { external: true });
};

const taskPrices = {
  monthly: stripeMonthlyTaskPriceId,
  yearly: stripeYearlyTaskPriceId,
};

const lineItems = computed(() => [
  // Main subscription plan
  {
    price: subscriptionPriceIds[props.term],
    quantity: 1,
  },
  // Additional tasks
  ...(sliderValue.value - monthlyActionLimit.value > 0
    ? [
        {
          price: taskPrices[props.term],
          quantity: sliderValue.value - monthlyActionLimit.value, // Don't need to include the base amount of tasks
        },
      ]
    : []),
]);

const cost = computed(() => {
  return (
    subscriptionPricePerMonth[props.term] +
    (sliderValue.value - monthlyActionLimit.value) * taskCost
  );
});

const monthlyActionLimit = computed(() =>
  subscriptionTypeToMonthlyActionLimit(type as SubscriptionType),
);

const subscribedMonthlyRunLimit = computed(() =>
  subscriptionTypeToMonthlyActionLimit(
    subscriptionType.value as SubscriptionType,
  ),
);

// If the monthly run limit of the card is greater than the currently subscribed plan's limit
const upgradeOrDowngrade = computed(() =>
  monthlyActionLimit.value > subscribedMonthlyRunLimit.value
    ? 'Upgrade'
    : 'Downgrade',
);

const buttonLabel = computed(() =>
  type === subscriptionType.value
    ? 'Currently subscribed'
    : upgradeOrDowngrade.value,
);

const sliderValue = ref(
  plan.type === subscriptionType.value
    ? monthlyActionLimit.value + monthlyAddonTasks.value
    : monthlyActionLimit.value,
);
const isSubscribed = subscriptionType.value !== 'free';
const isLoading = ref(false);

const delta = computed(
  () => sliderValue.value - monthlyActionLimit.value - monthlyAddonTasks.value,
);
const costNumber = computed(() => delta.value * taskCost);

const interval = [
  stripeStarterYearlyPriceId,
  stripeStarterMonthlyPriceId,
].includes(subscription.stripePriceId)
  ? 'year'
  : 'month';

const updateStripeSubscriptionItem = async () => {
  isLoading.value = true;

  const response = await $api(`/api/stripe/${workspaceId.value}/subscription`, {
    method: 'POST',
    body: {
      stripePriceId: subscriptionPriceIds[props.term], // Maintain the same priceId
      stripeSubscriptionId: subscription.value.stripeSubscriptionId,
      count: sliderValue.value - monthlyActionLimit.value,
    },
  });

  if (response.success) {
    refreshNuxtData('workspaces'); // TODO: This is an expensive operation
    toast.add({
      title: 'Successfully updated subscription.',
      id: 'modal-success',
    });
  } else {
    toast.add({
      title: response.message || 'Something went wrong.',
      id: 'modal-failure',
      color: 'rose',
    });
  }
  isLoading.value = false;
};

const planChangeCostNumber = computed(
  () =>
    subscriptionDetails[type as SubscriptionType].subscriptionPricePerMonth?.[
      `${interval}ly`
    ] -
    subscriptionDetails[subscriptionType.value].subscriptionPricePerMonth?.[
      `${interval}ly`
    ],
);

const handleSubscriptionChange = async () => {
  modal.open(ConfirmSubscriptionModal, {
    plan: {
      from: subscriptionType.value,
      to: type as SubscriptionType,
      cost: planChangeCostNumber.value,
    },
    tasks: {
      action: delta.value >= 0 ? 'add' : 'remove',
      count: Math.abs(delta.value),
      cost: costNumber.value,
    },
    workspaceName: workspace.value.name,
    interval: interval,
    onConfirm: updateStripeSubscriptionItem,
  });
};
</script>
<template>
  <UCard
    :ui="{
      header: {
        base: 'flex flex-col gap-2 items-start',
      },
      body: {
        base: 'flex flex-col gap-1 items-start',
        padding: '!py-0',
      },
      divide: 'divide-y-0',
    }"
    class="flex-1"
  >
    <template #header>
      <UBadge color="primary" variant="soft" size="sm">
        <UIcon
          :name="subscriptionDetails[type as SubscriptionType].icon"
          class="text-xl text-primary-500"
        />
        <p class="capitalize text-lg font-bold ml-2">{{ type }}</p>
      </UBadge>
      <p class="dimmed body-sm">{{ description }}</p>
    </template>

    <div class="contents">
      <p class="main-title" v-if="isEnterprise">Let's talk!</p>
      <p class="main-title" v-else>
        {{ formatCurrency(cost, 2) }}
        <span class="text-sm dimmed"> / per month </span>
      </p>

      <p class="caption" :class="isEnterprise && 'invisible'">
        Billed {{ term }}
      </p>

      <span
        class="flex gap-1 py-2 items-baseline"
        :class="isEnterprise && 'invisible'"
      >
        <UBadge color="primary" variant="subtle" size="lg">
          {{ sliderValue || 0 }}</UBadge
        >
        <p class="caption">Tasks per month*</p>
      </span>
      <URange
        v-model="sliderValue"
        :min="monthlyActionLimit"
        :max="10000"
        :step="100"
        class="w-full mt-1"
        :class="isEnterprise && 'invisible'"
        :disabled="plan.type === subscriptionType"
      />
      <UButton
        v-if="isEnterprise"
        color="white"
        block
        size="xl"
        to="mailto:sales@respell.ai"
        class="my-4"
      >
        Contact sales
      </UButton>
      <UButton
        v-else-if="plan.type === subscriptionType"
        :color="highlighted ? 'primary' : 'white'"
        block
        size="xl"
        class="my-4"
        disabled
      >
        Currently subscribed
      </UButton>
      <UButton
        v-else-if="isSubscribed"
        :color="highlighted ? 'primary' : 'white'"
        block
        size="xl"
        class="my-4"
        @click="handleSubscriptionChange"
        :loading="isLoading"
      >
        {{ buttonLabel }}
      </UButton>
      <UButton
        v-else
        :color="highlighted ? 'primary' : 'white'"
        block
        size="xl"
        class="my-4"
        @click="navigateToStripeSessionUrl"
      >
        {{ buttonLabel }}
      </UButton>
    </div>
    <template #footer>
      <p class="subtitle">
        {{ marketingTitle }}
      </p>
      <ul class="list-none mt-4">
        <li
          v-for="marketingItem in marketingItems"
          :key="marketingItem"
          class="flex gap-2 justify-start pb-2 items-start"
        >
          <UIcon
            name="i-ph-check-circle"
            class="text-lg shrink-0 text-primary-500"
          />
          <p class="text-sm dimmed">
            {{ marketingItem }}
          </p>
        </li>
      </ul>
    </template>
  </UCard>
</template>
