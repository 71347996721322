<script lang="ts" setup>
import { SubscriptionType } from '@respell/database';
import { subscriptionDetails } from '@respell/utils';
import { formatCurrency } from '~/util/formats';

const { onConfirm } = defineProps({
  plan: {
    from: {
      type: SubscriptionType,
      required: false,
    },
    to: {
      type: SubscriptionType,
      required: false,
    },
    cost: {
      type: Number,
      required: false,
    },
  },
  tasks: {
    action: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    cost: {
      type: Number,
      required: true,
    },
  },
  workspaceName: {
    type: String,
    required: true,
  },
  interval: {
    type: String,
    required: true,
  },
  onConfirm: {
    type: Function,
    required: true,
  },
});

const modal = useModal();

function modalOnConfirm() {
  onConfirm();
  modal.close();
}

defineShortcuts({
  enter: {
    handler: () => {
      onConfirm();
    },
  },
});
</script>

<template>
  <UModal>
    <UCard :ui="{ base: 'w-full' }">
      <div class="flex flex-col items-start gap-5">
        <div class="flex flex-row justify-between w-full">
          <p class="main-title capitalize">Change plan?</p>
          <UButton
            :padded="false"
            color="gray"
            variant="link"
            icon="i-ph-x"
            @click="modal.close()"
          />
        </div>

        <UAlert color="primary" variant="soft" :icon="null">
          <template #description>
            <ul class="list-disc pl-4 text-lg">
              <li v-if="plan && plan.from && plan.to" class="pb-2">
                Your plan will change from
                <strong>{{ subscriptionDetails[plan.from].shortName }}</strong>
                to
                <strong>{{ subscriptionDetails[plan.to].shortName }}</strong>
              </li>
              <li v-if="tasks.count" class="pb-2">
                You will
                {{ tasks.action }}
                <strong>{{ tasks.count }}</strong>
                add-on tasks
                {{ tasks.action === 'add' ? 'to' : 'from' }}
                your subscription
              </li>

              <li v-if="tasks.count" class="pb-2">
                This will
                {{ tasks.action === 'add' ? 'increase' : 'decrease' }}
                your
                {{ interval }}ly Tasks bill by
                <strong>{{ formatCurrency(Math.abs(tasks.cost), 2) }}</strong
                >*
              </li>
              <li v-if="plan && plan.cost" class="pb-2">
                This will {{ plan.cost > 0 ? 'increase' : 'decrease' }} your
                {{ interval }}ly Plan bill by
                <strong>{{ formatCurrency(Math.abs(plan.cost), 2) }}</strong
                >*
              </li>

              <li class="pb-2">
                Tasks and plan changes will be available immediately in the
                workspace:
                <strong>{{ workspaceName }}</strong>
              </li>
            </ul>
          </template>
        </UAlert>

        <span class="caption leading-tight"
          >* Your account will be billed at the same interval as your current
          subscription. Tasks will appear as an additional line item on your
          invoice at the rate noted above.
        </span>

        <div class="flex flex-row gap-2 justify-between w-full">
          <UButton
            label="Cancel"
            variant="solid"
            color="white"
            size="xl"
            block
            class="shrink"
            @click="modal.close()"
          />
          <UButton
            label="Update"
            size="xl"
            variant="solid"
            color="primary"
            block
            class="shrink"
            :ui="{ base: 'capitalize' }"
            @click="modalOnConfirm"
          />
        </div>
      </div>
    </UCard>
  </UModal>
</template>
