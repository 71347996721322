<script setup lang="ts">
import { SubscriptionType } from '@respell/database';
import { subscriptionDetails, type SubscriptionDetail } from '@respell/utils';
import BillingPricingCard from '~/components/account/BillingPricingCard.vue';

const plans: SubscriptionDetail[] = [
  subscriptionDetails[SubscriptionType.starter],
  subscriptionDetails[SubscriptionType.team],
  subscriptionDetails[SubscriptionType.enterprise],
];

const items = [
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Yearly',
    value: 'yearly',
  },
];
</script>
<template>
  <div class="flex flex-col gap-4 w-full items-start">
    <p class="main-title">All Plans</p>
    <UBadge color="primary" variant="subtle" size="lg">
      Save 20% annually
    </UBadge>
    <UTabs :items="items" class="mt-2">
      <template #item="{ item }">
        <div class="flex gap-4 mt-8 items-stretch">
          <BillingPricingCard
            v-for="plan in plans"
            :key="plan.type"
            :plan="plan"
            :term="item.value"
          />
        </div>
      </template>
    </UTabs>

    <p class="caption italic">
      *A task is a step run (excluding Start and Display steps), or a single
      agent task.
    </p>
  </div>
</template>
